import React from 'react'

const Contact = (props) => {
	return (
		<article className={'page content contact' + (props.show ? ' show' : '')}>
			<h1 className="content-title">İletişim</h1>

			<div className="content-text wysiwyg">
				<p>
					<strong>Talos Lab Medya ve Bilişim Teknolojileri LTD. ŞTİ.</strong><br />
					Bulgurlu Mahallesi, Market Sokak, 3A/A<br />
					Üsküdar/İSTANBUL<br />
					<a
						className="link"
						target="_blank"
						rel="noopener noreferrer"
						href="https://goo.gl/maps/zvoHBZ6jWTVANnbm9">
						Haritada Gör
					</a>
				</p>
				<p>
					<a href="tel:+905057143134">+90 (505) 714 31 34</a><br />
					<a href="mailto:keremuzman@gmail.com">keremuzman@gmail.com</a>
				</p>
			</div>
		</article>
	)
}

export default Contact