import React, { useState, useEffect } from 'react'

// Deps
import { connect } from "react-redux";

// Partials
import Image from 'views/partials/image'
import Link from 'views/partials/link'

// Static Assets
import Logo from 'assets/images/logo.svg'

const mapStateToProps = state => {
	return {
		currentPage: state.site.currentPage,
	};
};

const Navigation = (props) => {
	const [active, setActive] = useState(props.currentPage.data.showNav !== false);
	const [show, setShow] = useState(props.currentPage.data.showNav !== false);

	useEffect(() => {
		if(props.currentPage.data.showNav === false && active) {
			setShow(false);

			setTimeout(function() {
				setActive(false);
			}, 400);
		}
		else if(props.currentPage.data.showNav !== false && !active) {
			setActive(true);

			setTimeout(function() {
				setShow(true);
			}, 10);
		}
	}, [props.currentPage, active])

	if(active) {
		return (
			<aside className={'section navigation' + (show ? ' show' : '')}>
				<div className="navigation-innerwrap">
					<Link className="navigation-logowrap" href="home">
					<Image className="navigation-logo" src={Logo} />
					</Link>

					<nav className="navigation-menu">
						<div className="menu-item">
							<Link className="item-link" href="about" />
						</div>
						<div className="menu-item">
							<Link className="item-link" href="services" />
						</div>
						<div className="menu-item">
							<Link className="item-link" href="contact" />
						</div>
					</nav>

					{/* <nav className="navigation-social">
						<Link
							type="a"
							className="social-link"
							href="mailto:keremuzman@gmail.com"
							target="_blank"
							rel="noopener">
							<i className="icon-mail"></i>
						</Link>
						<Link
							type="a"
							className="social-link"
							href="https://www.linkedin.com/in/yutonet/"
							target="_blank"
							rel="noopener">
							<i className="icon-linkedin"></i>
						</Link>
						<Link
							type="a"
							className="social-link"
							href="https://www.github.com/yutonet"
							target="_blank"
							rel="noopener">
							<i className="icon-github"></i>
						</Link>
					</nav> */}
				</div>
			</aside>
		)
	}
	else {
		return false;
	}
}

export default connect(mapStateToProps)(Navigation);