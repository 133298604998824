import React from 'react'

const About = (props) => {
	return (
		<article className={'page content about' + (props.show ? ' show' : '')}>
			<h1 className="content-title">Hakkımızda</h1>

			<div className="content-text wysiwyg">
				<p>Sanal Prodüksiyon teknolojisinin gücünü yapım sektörü ile buluşturmayı amaçlayan Talos Lab, Unreal Engine'in gücünü de arkasına alarak gerçek zamanlı özel efekt stüdyosu olarak hizmet vermektedir.</p>
			</div>
		</article>
	)
}

export default About