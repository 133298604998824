import React from 'react'

const Services = (props) => {
	return (
		<article className={'page content services' + (props.show ? ' show' : '')}>
			<h1 className="content-title">Hizmetlerimiz</h1>

			<div className="content-text wysiwyg">
				<p>Sanal Prodüksiyon hizmetlerinin yanı sıra tam teşekküllü bir atolye olarak da kullanabileceğiniz Talos Lab, prodüksiyon aşamasında hızlıca çözüm üretebileceğiniz çok amaçlı bir alan.</p>

				<ul>
					<li>
						<strong>Virtual Production</strong>
					</li>
					<li>
						<strong>Atölye & Maker Stüdyo</strong>
					</li>
					<li>
						<strong>Technoprops Geliştirme</strong>
					</li>
					<li>
						<strong>Facial Capture</strong>
					</li>
					<li>
						<strong>Performance Capture</strong>
					</li>
					<li>
						<strong>Motion Capture</strong>
					</li>
					<li>
						<strong>VR & AR Uygulamaları</strong>
					</li>
					<li>
						<strong>VR Studio</strong>
					</li>
				</ul>
			</div>
		</article>
	)
}

export default Services