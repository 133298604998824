// Config
import devConfig from 'config/env/dev'
import prodConfig from 'config/env/prod'

const defaultConfig = {
	environment: 'default',
	titlePostFix: 'Talos Lab',
	mobileBreakPoint: 960,
	pageTransition: 450,
}

let env = process.env.REACT_APP_CONFIG_ENV ? process.env.REACT_APP_CONFIG_ENV : process.env.NODE_ENV;
let config;

switch(env) {
	case "production":
		config = {...defaultConfig, ...prodConfig};
	break;
	default:
		config = {...defaultConfig, ...devConfig};
	break;

}

if(env !== 'production') {
	window.dumpConfig = () => {
		console.log('Environment: ', env);
		console.log(config);
	}
}

export default config;