module.exports = {
	pages: {
		home: {
			path: "/",
			component: "Home",
			exact: true,
			linkTitle: "Home",
			showNav: false,
			title: "Sanal Prodüksiyon Stüdyosu",
			descriptiopn: "Sanal prodüksiyon teknolojisinin gücünü yapım sektörüne kazandırıyoruz.",
			// meta: {
			// 	keywords: "Talos Lab, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Talos Lab..."
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Talos Lab...",
			// 		props: {}
			// 	}
			// ],
		},
		about: {
			path: "/hakkimizda",
			component: "About",
			exact: true,
			linkTitle: "Hakkımızda",
			title: "Hakkımızda",
			descriptiopn: "Talos Lab sanal prodüksiyon stüdyosu hakkında.",
		},
		services: {
			path: "/hizmetlerimiz",
			component: "Services",
			exact: true,
			linkTitle: "Hizmetlerimiz",
			title: "Hizmetlerimiz",
			descriptiopn: "Sunduğumuz hizmetler.",
		},
		// works: {
		// 	path: "/isler",
		// 	component: "Works",
		// 	exact: true,
		// 	linkTitle: "Works",
		// 	title: "Works",
		// 	descriptiopn: "Projects I've worked on",
		// },
		// workDetail: {
		// 	path: "/isler/:slug",
		// 	component: "WorkDetail",
		// 	exact: true,
		// 	linkTitle: "Work Detail",
		// 	title: "Work",
		// 	descriptiopn: "Details about the project",
		// },
		contact: {
			path: "/iletisim",
			component: "Contact",
			exact: true,
			linkTitle: "İletişim",
			title: "İletişim",
			descriptiopn: "Bizimle iletişime geçin.a",
		},
		notFound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Sayfa Bulunamadı.",
			status: 404,
		},
		error: {
			path: false,
			component: "ErrorPage",
			exact: false,
			linkTitle: "Hata",
			title: "Bir Hata Oluştu",
			status: 500,
		},
	}
}